import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/index",
    name: 'Index',
    component: () => import(/* webpackChunkName: "Index" */ '../views/Index.vue')
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import(/* webpackChunkName: "Index" */ '../views/Index.vue')
  },
  //产品中心
  {
    path: '/customDev',
    name: 'customDev',
    component: () => import(/* webpackChunkName: "customDev" */ '../views/products/customDev.vue')
  },
  {
    path: '/techPlatform',
    name: 'techPlatform',
    component: () => import(/* webpackChunkName: "techPlatform" */ '../views/products/techPlatform.vue')
  },
  {
    path: '/workIssue',
    name: 'workIssue',
    component: () => import(/* webpackChunkName: "workIssue" */ '../views/products/workIssue.vue')
  },
  {
    path: '/dataServices',
    name: 'dataServices',
    component: () => import(/* webpackChunkName: "dataServices" */ '../views/products/dataServices.vue')
  },

  //解决方案
  {
    path: '/animalBank',
    name: 'animalBank', // 模式动物管理系统
    component: () => import(/* webpackChunkName: "animalBank" */ '../views/cases/animalBank.vue')
  },
  {
    path: '/bioBank',
    name: 'bioBank', //医疗机构生物样本库管理系统
    component: () => import(/* webpackChunkName: "bioBank" */ '../views/cases/bioBank.vue')
  },
  {
    path: '/clinic',
    name: 'clinic', //临床研究统计分析平台
    component: () => import(/* webpackChunkName: "clinic" */ '../views/cases/clinic.vue')
  },
  {
    path: '/compound',
    name: 'compound', // 化合物库信息管理系统
    component: () => import(/* webpackChunkName: "compound" */ '../views/cases/compound.vue')
  },
  {
    path: '/envBank',
    name: 'envBank',//环境样本管理系统
    component: () => import(/* webpackChunkName: "envBank" */ '../views/cases/envBank.vue')
  },
  {
    path: '/experimentCenter',
    name: 'experimentCenter', //实验中心一体化信息管理平台
    component: () => import(/* webpackChunkName: "experimentCenter" */ '../views/cases/experimentCenter.vue')
  },
  {
    path: '/gene',
    name: 'gene', //基因数据库管理系统
    component: () => import(/* webpackChunkName: "gene" */ '../views/cases/gene.vue')
  },
  {
    path: '/germBank',
    name: 'germBank', //种质资源库管理系统
    component: () => import(/* webpackChunkName: "germBank" */ '../views/cases/germBank.vue')
  },
  {
    path: '/instrumentShare',
    name: 'instrumentShare',//仪器设备共享管理系统
    component: () => import(/* webpackChunkName: "instrumentShare" */ '../views/cases/instrumentShare.vue')
  },
  {
    path: '/medicineBank',
    name: 'medicineBank',//药企样品管理系统
    component: () => import(/* webpackChunkName: "medicineBank" */ '../views/cases/medicineBank.vue')
  },
  {
    path: '/multicenter',
    name: 'multicenter',//多中心研究样本与数据管理平台
    component: () => import(/* webpackChunkName: "multicenter" */ '../views/cases/multicenter.vue')
  },
  {
    path: '/science',
    name: 'science',//科研项目管理系统
    component: () => import(/* webpackChunkName: "science" */ '../views/cases/science.vue')
  },
  {
    path: "/about",
    name: "about", //关于我们
    component: () => import(/* webpackChunkName: "science" */ '../views/about/index.vue')

  },
  {
    path: "/news",
    name: "news", //新闻资讯
    component: () => import(/* webpackChunkName: "news" */ '../views/news/index.vue')
  },
  //新闻详情路由  拼接
  {
    path: '/news/:id',
    name: "detail", //新闻资讯
    component: () => import('@/views/news/detail.vue'),
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})
//屏蔽重复路由警告
const VueRouterPush = router.push
router.push = function push(to) {
  undefined
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
