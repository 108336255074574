<template>
  <div id="app">
    <Nav />
    <router-view />
    <Bottom />
  </div>
</template>
<script>
import Nav from '@/components/Nav.vue'
import Bottom from '@/components/bottom.vue'
export default {
  components: {
    Nav,
    Bottom
  }

}
</script>
<style lang="less">
body {
  font-family: PingFangSC-, PingFang SC;
}

.banxin {
  width: 1200px;
  margin: 0 auto;
}

// #app {
//   max-width: 1920px;
//   overflow: hidden;
//   margin: 0 auto;
// }
</style>
