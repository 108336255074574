<template>
  <div class="nav-box">
    <div class="nav">
      <img class="logo" @click="() => this.$router.push('/')" src="@/assets/images/home/nav-logo.png" />
      <div class="right-menu">
        <!-- @mouseleave="() => selectMenu = []" -->
        <div class="menu-item" v-for="(item, index) in menuData" @mouseover="navLink(item)"
          @click="gotoFirstMenu(item.path)" :key="index">
          {{ item.title }}
          <img :src="selectIcon.downImg" v-show="item.children">
        </div>
        <!-- 二级菜单 -->
        <div class="select-children" v-show="isSelectMenuShow" @mouseleave="() => isSelectMenuShow = false">
          <div class=" sec-menu" ref="secMenu" v-for="(item, index) in selectMenu " :key="index"
            @click="gotoSecMenu(item.path)">{{
                item.secTitle
            }}</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      menuData: [
        {
          title: "首页",
          path: "/index",
          children: null
        },
        {
          title: "产品中心",
          path: "",
          id: 2,
          children: [
            {
              path: "/customDev",
              secTitle: "定制开发"
            },
            {
              path: "/techPlatform",
              secTitle: "技术平台"
            },
            {
              path: "/workIssue",
              secTitle: "实施顾问"
            },
            {
              path: "/dataServices",
              secTitle: "数据服务"
            },
          ]
        },
        {
          title: "解决方案",
          path: "",
          children: [
            //解决方案
            {
              path: '/animalBank',
              secTitle: '模式动物管理系统', // 模式动物管理系统
            },
            {
              path: '/germBank',
              secTitle: '种质资源库管理系统', //种质资源库管理系统
            },
            {
              path: '/envBank',
              secTitle: '环境样本管理系统',//环境样本管理系统
            },
            {
              path: '/bioBank',
              secTitle: '医疗机构生物样本库管理系统', //医疗机构生物样本库管理系统
            },

            {
              path: '/science',
              secTitle: '科研项目管理系统',//科研项目管理系统
            },
            {
              path: '/gene',
              secTitle: '基因数据库管理系统', //基因数据库管理系统
            },
            {
              path: '/medicineBank',
              secTitle: '药企样品管理系统',//药企样品管理系统
            },
            {
              path: '/experimentCenter',
              secTitle: '实验中心一体化信息管理平台', //实验中心一体化信息管理平台
            },
            {
              path: '/compound',
              secTitle: '化合物库信息管理系统', // 化合物库信息管理系统
            },
            {
              path: '/instrumentShare',
              secTitle: '仪器设备共享管理系统',//仪器设备共享管理系统
            },
            {
              path: '/clinic',
              secTitle: '临床研究统计分析平台', //临床研究统计分析平台
            },
            {
              path: '/multicenter',
              secTitle: '多中心研究样本与数据管理平台',//多中心研究样本与数据管理平台
            },
          ]
        },
        {
          title: "新闻资讯",
          path: "/news",
          children: null
        },
        {
          title: "云服务",
          path: "",
          children: null
        },
        {
          title: "关于我们",
          path: "/about",
          children: null
        },
      ],
      selectMenu: [], //二级路由菜单
      isSelectMenuShow: false,//控制二级菜单的下拉, 显示隐藏
      selectIcon: {
        icon: "",
        upImg: require("@/assets/images/home/nav-up.png"),
        downImg: require("@/assets/images/home/nav-down.png"),
      }
    }
  },
  methods: {
    gotoFirstMenu(path) { //一级菜单跳转
      if (path) {
        this.$router.push(path)
      } else {
        this.$message({
          showClose: true,
          message: '该功能暂未开放'
        });
      }
    },
    navLink(item) {  //获取下拉菜单
      if (item.children) {
        this.selectMenu = item.children
        this.isSelectMenuShow = true
        if (item.id == 2) {
          this.$nextTick(() => {
            let secMenuList = this.$refs.secMenu
            secMenuList.forEach(item => {
              item.style = 'text-align:center'
            })
          })
        } else {
          this.$nextTick(() => {
            let secMenuList = this.$refs.secMenu
            secMenuList.forEach(item => {
              item.style = 'text-align:left'
            })
          })
        }
      } else {
        this.isSelectMenuShow = false
      }
    },

    gotoSecMenu(path) { //二级菜单跳转
      this.$router.push(path)
    }
  }
}
</script>

<style lang="less" scoped>
.nav-box {
  // background: skyblue;
  background: #fff;
  position: sticky;
  top: 0;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.1600);
  width: 100%;
  z-index: 10086;

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    height: 72px;
    box-sizing: border-box;
    // background-color: skyblue;

    .logo {
      display: block;
      width: 136px;
      height: 37px;
      cursor: pointer;
    }

    .right-menu {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      .menu-item {
        // background: salmon;
        margin-left: 85px;
        padding: 28px 0;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
          // background: #2A5CE9;
          color: #2A5CE9;
        }
      }

      .select-children {
        position: absolute;
        background: rgba(255, 255, 255, .9);
        backdrop-filter: blur(32px);
        // background: #d9d9d9;
        width: 100%;
        // height: 200px;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1600);
        border-radius: 10px;
        top: 72px;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 20px;

        .sec-menu-product {
          text-align: center;
        }

        .sec-menu {
          text-align: left;
          padding: 20px 14px;
          min-width: 163px;
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          line-height: 22px;
          border-radius: 5px;
          cursor: pointer;

          &:nth-child(4n) {
            min-width: 232px;
          }

          &:hover {
            cursor: pointer;
            color: #fff;
            background: rgba(42, 92, 233, .6);
          }
        }
      }
    }
  }

}
</style>